<app-PersonalNav [title]="'Propostas Recebidas'"></app-PersonalNav>
<div class="container mw-100">
  <div class="row">
    <div class="col commands text-right">
      <button type="submit" [routerLink]="['/searchChallenges']" mat-raised-button color="primary" style="margin-top: 30px; margin-bottom: 20px;">Adicionar Nova Proposta</button>
    </div>
    <div class="col">
      <div class="d-flex justify-content-center align-items-center">
        <h5>Seletor</h5>
        <div fxLayout fxLayoutAlign="center center" style="margin-top: 10px; margin-left: 10px;">
          <mat-form-field fxFlex="60%">
            <mat-label>Selecione o Desafio</mat-label>
            <mat-select [(value)]="selectedChallenge" (selectionChange)="getSolutionByChallenge($event)">
              <mat-option *ngFor="let challenge of challenges" [value]="challenge">
                {{challenge.theme}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="d-flex justify-content-center align-items-center">
        <h5>Filtro</h5>
        <div fxLayout fxLayoutAlign="center center" style="margin-top: 10px; margin-left: 10px;">
          <mat-form-field fxFlex="60%">
            <input matInput type="text" (keyup)="applyFilter($event)" placeholder="Buscar Solução">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="solutionsInTable">
      
      <!-- Column Definitions -->
      <!-- Use the headers from the provided image to name your columns -->

      <!-- Proposal ID Column -->
      <ng-container matColumnDef="proposalId">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element"> {{element.solutionId}} </td>
      </ng-container>

      <!-- Organization Column -->
      <ng-container matColumnDef="organization">
        <th mat-header-cell *matHeaderCellDef> ORGANIZAÇÃO </th>
        <td mat-cell *matCellDef="let element"> {{element.organizationResponsibleName}} </td>
      </ng-container>

      <!-- Responsible (Name of Innovator) Column -->
      <ng-container matColumnDef="ownerName">
        <th mat-header-cell *matHeaderCellDef> RESPONSÁVEL </th>
        <td mat-cell *matCellDef="let element"> {{element.organizationResponsibleName}} </td>
      </ng-container>

      <!-- Area (Solution Area) Column -->
      <ng-container matColumnDef="challengeField">
        <th mat-header-cell *matHeaderCellDef> ÁREA </th>
        <td mat-cell *matCellDef="let element"> {{element.typeOfSolution}} </td>
      </ng-container>

      <!-- Theme (Central Theme of Solution) Column -->
      <ng-container matColumnDef="theme">
        <th mat-header-cell *matHeaderCellDef> TEMA </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <!-- Challenge (Problem Description) Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> DESAFIO </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <!-- Objectives Column -->
      <ng-container matColumnDef="objectives">
        <th mat-header-cell *matHeaderCellDef> Nome da Solução </th>
        <td mat-cell *matCellDef="let element"> {{element.objectives}} </td>
      </ng-container>

      <!-- MVP Features Column -->
      <ng-container matColumnDef="mvpFeat">
        <th mat-header-cell *matHeaderCellDef> MVP FEAT. </th>
        <td mat-cell *matCellDef="let element"> {{element.mvpFeat}} </td>
      </ng-container>

      <!-- Operational Gains Column -->
      <ng-container matColumnDef="operationalGain">
        <th mat-header-cell *matHeaderCellDef> GANHOS OPERACIONAIS </th>
        <td mat-cell *matCellDef="let element"> {{element.operationalGain}} </td>
      </ng-container>

      <!-- Economic Gains Column -->
      <ng-container matColumnDef="economicGain">
        <th mat-header-cell *matHeaderCellDef> GANHOS ECONOMICOS </th>
        <td mat-cell *matCellDef="let element"> {{element.economicGain}} </td>
      </ng-container>

        <!-- Maturity Desired (TRL of the solution) Column -->
  <ng-container matColumnDef="trl">
    <th mat-header-cell *matHeaderCellDef> TRL </th>
    <td mat-cell *matCellDef="let element"> {{element.trl}} </td>
  </ng-container>

  <!-- Necessary Value for the 1st product version Column -->
  <ng-container matColumnDef="availableFees">
    <th mat-header-cell *matHeaderCellDef> $ VERBA NECESSÁRIA </th>
    <td mat-cell *matCellDef="let element"> {{element.availableFees | currency:'BRL'}} </td>
  </ng-container>

  <!-- Annex (Presentation in PDF or PPTx) Column -->
  <ng-container matColumnDef="urlPresentation">
    <th mat-header-cell *matHeaderCellDef> ANEXO </th>
    <td mat-cell *matCellDef="let element"> <a href="{{element.urlPresentationData}}" target="_blank">Apresentação</a> </td>
  </ng-container>

  <!-- Link (Video) Column -->
  <ng-container matColumnDef="videoLink">
    <th mat-header-cell *matHeaderCellDef> LINK (Vídeo) </th>
    <td mat-cell *matCellDef="let element"> <a href="{{element.videoLink}}" target="_blank">Vídeo</a> </td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> STATUS </th>
    <td mat-cell *matCellDef="let element"> 
      <select (change)="updateStatus(element, $event.target)" [value]="element.status">
        <option *ngFor="let status of statusOptions" [ngValue]="status">{{status}}</option>
      </select>
    </td>
  </ng-container>
  

  <!-- Header and Row Definitions -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDialog(row, selectedChallenge)"></tr>
</table>
  </div>
</div>

