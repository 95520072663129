import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-DialogOwnership',
  templateUrl: './DialogOwnership.component.html',
  styleUrls: ['./DialogOwnership.component.css']
})
export class DialogOwnershipComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogOwnershipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
