<app-PersonalNav [title]="'Empresa'"></app-PersonalNav>
<div class="container">

  <hr>
  <div class="row">
    <div class="col d-flex justify-content-around" *ngIf="isOrganizationOwner">
      <h4>Owner da Empresa:</h4> <h3>{{this.user.nome}}</h3> 
      <!-- button for add user as admin -->
      <button mat-raised-button color="warn" (click)="addAdmin()">Administração de Usuários</button>

    </div>
  </div>
  <form [formGroup]="organizationForm" (ngSubmit)="onSubmit()" class="m-3">
    <div class="row">
      <div class="col">
        <!-- CNPJ -->
        <div class="form-group">
          <label for="cnpj">CNPJ <span class="required-indicator">*</span></label>
          <input type="text" class="form-control" mask="00.000.000/0000-00" id="cnpj" formControlName="cnpj">
          <div *ngIf="organizationForm.get('cnpj')?.invalid && organizationForm.get('cnpj')?.touched">
            <small *ngIf="organizationForm.get('cnpj')?.errors?.['required']" class="text-danger">CNPJ é obrigatório.</small>
            <small *ngIf="organizationForm.get('cnpj')?.errors?.['cnpjInvalid']" class="text-danger">CNPJ inválido.</small>
          </div>
        </div>
      </div>
      
  
      <div class="col">
        <div class="row">

          <div class="col">
            
            <div class="form-group">
              <label for="organizationType">Tipo de Empresa <span class="required-indicator">*</span></label>
            <select class="form-select" id="organizationType" formControlName="typeId">
              <option value="">Selecione o tipo</option>
              <ng-container *ngFor="let group of typeOrganizations">
                <optgroup [label]="group.classification">
                  <ng-container *ngFor="let subGroup of group.subClasses">
                    <!-- Display the subClass name if it's not 'Main' -->
                    <ng-container *ngIf="subGroup.name !== 'Main'">
                      <option [disabled]="true" style="font-weight: bold;">{{ subGroup.name }}</option>
                    </ng-container>
                    <!-- Indent the types that belong to a subClass -->
                    <option *ngFor="let type of subGroup.types" [value]="type.id">
                      {{ subGroup.name !== 'Main' ? '→ ' : '' }}{{ type.name }}
                    </option>
                  </ng-container>
                </optgroup>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="example-margin">É StartUp? <span class="required-indicator">*</span></label>

            <select class="form-select" id="ehStartup" formControlName="ehStartup">
              <option value="">Selecione uma opção</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>

          </div>
        </div>
        
      </div>
        

        

      </div>

    </div>

    <div class="row">
      <div class="col">
        <div class="form-group ">
          <label for="name">Nome <span class="required-indicator">*</span></label>
          <input type="text" class="form-control" id="name" formControlName="name">
        </div>

      </div>
      
  
      <div class="col">
        <div class="form-group ">
          <label for="phoneNumber">Telefone <span class="required-indicator">*</span></label>
          <input type="tel" class="form-control" id="phone" mask="(00) 00000-0000" formControlName="phoneNumber">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group ">
          <label for="email">Email <span class="required-indicator">*</span></label>
          <input type="email" class="form-control" id="email" formControlName="email">
        </div>

      </div>
      <div class="col">

        <!-- Field -->
        <div class="form-group ">
          <label for="field">Área de Atuação <span class="required-indicator">*</span></label>
          <input type="text" class="form-control" id="field" formControlName="field">
        </div>
      </div>
    </div>



<div class="row">
  <div class="col">
    <div class="form-group">
      <label for="revenue">Receita Anual <span class="required-indicator">*</span></label>
      <select class="form-select" id="revenue" formControlName="revenue">
        <ng-container *ngFor="let category of revenueCategories">
          <optgroup label="{{ category.groupName }}">
            <option *ngFor="let option of category.options" [value]="option.value">
              {{ option.displayValue }}
            </option>
          </optgroup>
        </ng-container>
      </select>
    </div>
  </div>
  
  <div class="col">
    <!-- Foundation Date -->
    <div class="form-group ">
      <label for="foundationYear">Ano de Fundação <span class="required-indicator">*</span></label>
      <input type="text" class="form-control" id="foundationYear" formControlName="foundationYear">
    </div>

  </div>
</div>

<div class="row">
  <div class="col">
    <div class="form-group ">
      <label for="address">Endereço <span class="required-indicator">*</span></label>
      <input type="text" class="form-control" id="address" formControlName="address">
    </div>
  </div>
  <div class="col">
    <!-- Presentation PDF -->
    <div class="form-group ">
      <label for="presentation">Apresentação da Empresa</label>
      <app-file-upload formControlName="presentation" [allowedTypes]="['application/pdf']"></app-file-upload>
    </div>

  </div>
</div>

    

    <div class="form-group ">
      <label for="description">Descrição da Empresa</label>
      <input class="form-control" id="briefDescription"  formControlName="briefDescription">
    </div>
    <div class="spinner">
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>
    <div class="buttonControl">
      <button type="submit" mat-raised-button color="warn" >Salvar</button>
    </div>
    <!-- <button type="submit" mat-raised-button color="warn" >Salvar</button> -->

  </form>
</div>