<h2 mat-dialog-title>Proposta de solução para {{proposal.problem.theme}}</h2>
<mat-dialog-content class="mat-typography">
  <h3>Empresa: {{proposal.problem.ownerName}}</h3>
  <p>Descrição do desafio: {{proposal.problem.description}}</p>

  <h3>Ganhos &amp; Operacionais</h3>
  <p>{{proposal.problem.operationalGain}}</p>

  <h3>MVP-Features</h3>
  <p>{{proposal.problem.mvpFeat}}</p>

  <h3>Ganhos Econômicos</h3>
  <p>{{proposal.problem.econimicGain}}</p>

  <hr>
  <h3>Tipo de Solução - {{proposal.solution.typeOfSolution}} </h3>

  <p>{{proposal.solution.description}}</p>

  <!-- <h3>Objetivos da Solução</h3>

  <p>{{proposal.solution.objectives}}</p> -->

  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fechar</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
