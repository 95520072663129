import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/api/base/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
constructor(
  private _user: UserService,
  private _toastr: ToastrService,
  private _route: Router
) { }


public checkIfItsLogedIn() {
  var user = this._user.obterUsuario();
  if (Object.keys(user).length === 0) {
    this._toastr.error(
      'Para acessar esse ambiente é preciso estar autenticado'
    );
    this._route.navigateByUrl('login');
  } 
}

public checkIfProfileIsComplete() {
  var user = this._user.obterUsuario();
  if (!user.completedProfile) {
    return false;
  }
  return true;
}

public checkRights(ehAdmin: boolean) {
  var user = this._user.obterUsuario();
  if (Object.keys(user).length === 0) {
    this._toastr.error(
      'Para acessar esse ambiente é preciso estar autenticado'
    );
    this._route.navigateByUrl('/login');
  } else {
    let check = user.claims.map(
      (item: { type: string }) => item.type === 'role'
    );
    if (check.includes(true)) {
      let rights = user.claims.map(
        (item: { type: string }) => item.type === 'role'
      );    
      if (rights.value.includes('Admin')) ehAdmin = true;
    }
    if (!ehAdmin) {
      this._toastr.error(
        'Apenas administradores podem acessar este ambiente'
      );
      this._route.navigateByUrl('/login');
    }
  }
}

public allowAccessOnlyToMaster(): boolean | undefined {
  var user = this._user.obterUsuario();
  if (Object.keys(user).length === 0) {
    this._toastr.error(
      'Para acessar esse ambiente é preciso estar autenticado'
    );
    this._route.navigateByUrl('/login');
  }
  let check = user.claims.map(
    (item: { type: string }) => item.type === 'role'
  );
  if (check.includes(true)) {
    let rights = user.claims.find(
      (item: { type: string }) => item.type === 'role'
    );
    if (rights.value.includes('Master')) return true;
  }
  this._toastr.error(
    'Apenas o Master pode acessar este ambiente'
  );
  this._route.navigateByUrl('/login');
  return false;
}


public checkIfItsOwner() {
  var user = this._user.obterUsuario();
  let claims = user.claims.map((item: { type: string }) => item.type === 'role');
  if (claims.includes(true)) {
    let rights = user.claims.find((item: { type: string }) => item.type === 'role');
    if (rights.value.includes('Owner') || rights.value.includes('Admin')) return true;
  }
  return false;
}

public checkIfItsMaster() {
  var user = this._user.obterUsuario();
  let claims = user.claims.map((item: { type: string }) => item.type === 'role');
  if (claims.includes(true)) {
    let rights = user.claims.find((item: { type: string }) => item.type === 'role');
    if (rights.value.includes('Master')) return true;
  }
  return false;
}
}
