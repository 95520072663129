import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { faArrowAltCircleLeft, faArrowCircleLeft, faArrowLeft, faArrowRotateBackward, faHome, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { AccessService } from 'src/services/access.service';
import { UserService } from 'src/api/base/user/user.service';

@Component({
  selector: 'app-PersonalNav',
  templateUrl: './PersonalNav.component.html',
  styleUrls: ['./PersonalNav.component.css']
})
export class PersonalNavComponent implements OnInit {

  faUnlockAlt = faUnlockAlt;
  faHome = faHome;
  faArrowBack = faArrowAltCircleLeft;

  faPassword = faUnlockAlt;

  faArrowAltCircleLeft = faArrowAltCircleLeft;

  
  constructor(private location: Location,
    private _access: AccessService,
    private _user: UserService,
    private router: Router) { }
  @Input() title: string = '';
  @Input() isMaster!: boolean;
  ngOnInit() {
    this.isMaster = this._access.checkIfItsMaster();
  }

  alterSenha(){
    this.router.navigateByUrl('alteraSenha');
  }

  goback(){
    this.location.back();
  }

  goAdmin(){
    this.router.navigateByUrl('master');
  }

}
