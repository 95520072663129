<app-PersonalNav [title]="'Inovador'"></app-PersonalNav>
<div class="container">
  <hr>
  <!-- <h1>{{label}}</h1> -->

  <form [formGroup]="personForm" (ngSubmit)="transformPerson()" class="profile-form">
    <!-- Common fields... -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="name">Nome <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="name" formControlName="name" required>
        <span *ngIf="personForm.get('name')?.touched">
          <span *ngIf="personForm.get('name')?.errors?.['required']" class="text-danger">
            Nome é obrigatório.
          </span>
        </span>
      </div>
    </div>

    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="birthDate">Data de Nascimento <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        
        <input type="date" class="form-control" id="birthDate" formControlName="birthDate" required>
      </div>
    </div>

    

    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="email">Email (login)<span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input type="email" class="form-control" id="email" formControlName="email" required>
        <span *ngIf="personForm.get('email')?.touched">
          <span *ngIf="personForm.get('email')!.errors?.['required']" class="text-danger">
            Email é obrigatório.
          </span>
          <span *ngIf="personForm.get('email')!.errors?.['email']" class="text-danger">
            Por favor, insira um endereço de email válido.
          </span>
        </span>
      </div>
    </div>

    <!-- PhoneNumber description -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="description">Celular <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input class="form-control" id="briefDescription" rows="3" formControlName="mobileNumber" mask="(00) 00000-0000" required>
      </div>
    </div>

    <!-- CPF -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="cpf">CPF <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="cpf" formControlName="cpf" mask="000.000.000.00" required [readonly]="contemCPF">
        <span *ngIf="personForm.get('cpf')?.touched">
          <span *ngIf="personForm.get('cpf')?.errors?.['required']" class="text-danger">
            CPF é obrigatório.
          </span>
        </span>
      </div>
    </div>

    <!-- whatsapp -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="whatsapp">WhatsApp<span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="whatsapp" formControlName="whatsAppNumber" mask="(00) 00000-0000" required>
        <span *ngIf="personForm.get('whatsAppNumber')?.touched">
          <span *ngIf="personForm.get('whatsAppNumber')?.errors?.['required']" class="text-danger">
            WhatsApp é obrigatório.
          </span>
        </span>
      </div>
    </div>

    <!-- Address -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="address">Endereço <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="address" formControlName="address" >
        <span *ngIf="personForm.get('address')?.touched">
          <span *ngIf="personForm.get('address')?.errors?.['required']" class="text-danger">
            Endereço é obrigatório.
          </span>
        </span>
      </div>
    </div>

    <!-- First Interested Area -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="firstInterestedArea">Área de Interesse Principal <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <select name="" formControlName="firstInterestedArea" class="form-select">
          <ng-container *ngFor="let group of formationAreas">
            <optgroup label="{{ group.classification }}">
              <option *ngFor="let item of group.items" [value]="item.name">{{item.name}}</option>
            </optgroup>
          </ng-container>
        </select>
        <span *ngIf="personForm.get('firstInterestedArea')?.touched">
          <span *ngIf="personForm.get('firstInterestedArea')?.errors?.['required']" class="text-danger">
            Área de Interesse Principal é obrigatória.
          </span>
        </span>        
      </div>
    </div>

    <!-- Second Interested Area -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="secondInterestedArea">Área de Interesse Secundária  <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <select name="" formControlName="secondInterestedArea" class="form-select">
          <ng-container *ngFor="let group of formationAreas">
            <optgroup label="{{ group.classification }}">
              <option *ngFor="let item of group.items" [value]="item.name">{{item.name}}</option>
            </optgroup>
          </ng-container>
        </select>
        <span *ngIf="personForm.get('secondInterestedArea')?.touched">
          <span *ngIf="personForm.get('secondInterestedArea')?.errors?.['required']" class="text-danger">
            Área de Interesse Secundária é obrigatória.
          </span>
        </span>
      </div>
    </div>
    <!-- Graduation -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="formationDegree">Grau da Maior Formação <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <select class="form-select" formControlName="formationDegree">
          <option [value]="item" *ngFor="let item of formationDegrees">{{item}}</option>
        </select>
      </div>
    </div>

    <!-- Formation Major Area -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="formationMajorArea">Área da Maior Formação <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <select name="" formControlName="formationMajorArea" class="form-select">
          <ng-container *ngFor="let group of formationAreas">
            <optgroup label="{{ group.classification }}">
              <option *ngFor="let item of group.items" [value]="item.name">{{item.name}}</option>
            </optgroup>
          </ng-container>
        </select>
        <span *ngIf="personForm.get('formationMajorArea')?.touched">
          <span *ngIf="personForm.get('formationMajorArea')?.errors?.['required']" class="text-danger">
            Área da Maior Formação é obrigatória.
          </span>
        </span>
      </div>
    </div>

    <!-- Graduation Area -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="graduationArea">Graduação <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">

        <select name="" formControlName="graduationArea" class="form-select">
          <ng-container *ngFor="let group of formationAreas">
            <optgroup label="{{ group.classification }}">
              <option *ngFor="let item of group.items" [value]="item.name">{{item.name}}</option>
            </optgroup>
          </ng-container>
        </select>
        <span *ngIf="personForm.get('graduationArea')?.touched">
          <span *ngIf="personForm.get('graduationArea')?.errors?.['required']" class="text-danger">
            Graduação é obrigatória.
          </span>
        </span>
        
      </div>
    </div>

    <!-- formation Pending -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="formationPending">Grau da Formação em andamento <span class="required-indicator"></span></label>
      </div>
      <div class="col-sm-8">
        <select name="" formControlName="formationPending" class="form-select">
          <option *ngFor="let item of formationDegrees" [value]="item">{{item}}</option>
        </select>
      </div>
    </div>

    <!-- Formation Pending Area -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="formationPendingArea">Área de Formação em andamento <span class="required-indicator"></span></label>
      </div>
      <div class="col-sm-8">
        <select name="" formControlName="formationPendingArea" class="form-select">
          <ng-container *ngFor="let group of formationAreas">
            <optgroup label="{{ group.classification }}">
              <option *ngFor="let item of group.items" [value]="item.name">{{item.name}}</option>
            </optgroup>
          </ng-container>
        </select>
        
      </div>
    </div>

    


    
    
    
    <!-- Linkedin address -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="linkedin">Linkedin </label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="linkedin" formControlName="linkedin">
      </div>
    </div>
    
    <!-- Discriminator Field -->
<!-- Role Type Field -->
<div class="form-group row">
  <div class="left-column col-sm-4 col-form-label">
    <label for="roleType">Tipo de Vínculo Profissional<span class="required-indicator">*</span></label>
  </div>
  <div class="col-sm-8">
    <select class="form-select" id="roleType" formControlName="role" (change)="onRoleChange($event)" required>
      <!-- Populate with role types -->
      <option value="" selected>Selecione o Tipo de Vínculo</option>
      <option *ngFor="let role of roleTypes" [value]="role.value">{{role.label}}</option>
      
    </select>
  </div>
</div>


    
    
  <!-- Professor Specific Fields -->
  <div class="form-group row" *ngIf="currentRole === 'Professor'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="institution">Instituição<span class="required-indicator">*</span></label>
    </div>
    <div class="col-sm-8" formGroupName="professorDetails">
      <p-autoComplete 
        formControlName="myControl"
        [suggestions]="filteredOrganizations"
        (completeMethod)="filterOrganizations($event)"
        (onSelect)="onInstitutionSelected($event)"
        optionLabel="name"
        [dropdown]="true"
        [showClear]="true"
        placeholder="Selecione uma Instituição"
      ></p-autoComplete>
    </div>
  </div>

  <!-- Student Specific Fields -->
  <div class="form-group row" *ngIf="currentRole === 'Student'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="institution">Instituição<span class="required-indicator">*</span></label>
    </div>
    <div class="col-sm-8" formGroupName="studentDetails">
      <p-autoComplete 
        formControlName="myControl"
        [suggestions]="filteredOrganizations"
        (completeMethod)="filterOrganizations($event)"
        (onSelect)="onInstitutionSelected($event)"
        optionLabel="name"
        [dropdown]="true"
        [showClear]="true"
        placeholder="Selecione uma Instituição"
      ></p-autoComplete>
    </div>
  </div>

  <!-- Researcher Specific Fields -->
  <div class="form-group row" *ngIf="currentRole === 'Researcher'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="institution">Instituição<span class="required-indicator">*</span></label>
    </div>
    <div class="col-sm-8" formGroupName="researcherDetails">
      <p-autoComplete 
        formControlName="myControl"
        [suggestions]="filteredOrganizations"
        (completeMethod)="filterOrganizations($event)"
        (onSelect)="onInstitutionSelected($event)"
        optionLabel="name"
        [dropdown]="true"
        [showClear]="true"
        placeholder="Selecione uma Instituição"
      ></p-autoComplete>
    </div>
  </div>

  <!-- Investor Specific Fields -->
  <div class="form-group row" *ngIf="currentRole === 'Investor'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="company">Empresa<span class="required-indicator">*</span></label>
    </div>
    <div  class="col-sm-8" formGroupName="investorDetails">
      <select class="form-select"  id="company" formControlName="company" (change)="handleOrganizationSelection($event)">
        <option value="">Selecione a Empresa</option>
        <option *ngIf="firstOrganization" [value]="firstOrganization.organizationId" [selected]="firstOrganization.organizationId">{{firstOrganization.name}}</option>
        <option *ngFor="let item of companies" [value]="item.organizationId">{{item.name}}</option>
        <option value="none">Outra</option>
      </select>
      <input type="text" class="form-control mt-4" *ngIf="showOrganizationInput" formControlName="newOrganizationName" placeholder="Insira o nome da Empresa" />
      <br>
      <select class="form-select" *ngIf="showOrganizationInput" formControlName="typeOrganization">
        <option value="">Selecione o Tipo de Empresa</option>
        <option *ngFor="let type of typeOrganizations" [value]="type.id">{{type.name}}</option>
      </select>
    </div>
  </div>

  <!-- Public Agent Specific fields -->
  <div class="form-group row" *ngIf="currentRole === 'PublicAgentState'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="company">Empresa<span class="required-indicator">*</span></label>
    </div>
    <div  class="col-sm-8" formGroupName="publicAgentDetailsState">
      <select class="form-select"  id="company" formControlName="company" (change)="handleOrganizationSelection($event)">
        <option value="">Selecione a Empresa</option>
        <option *ngIf="firstOrganization" [value]="firstOrganization.organizationId" [selected]="firstOrganization.organizationId">{{firstOrganization.name}}</option>
        <option *ngFor="let item of companiesFromGov" [value]="item.organizationId">{{item.name}}</option>
        <option value="none">Outra</option>
      </select>
      <input type="text" class="form-control mt-4" *ngIf="showOrganizationInput" formControlName="newOrganizationName" placeholder="Insira o nome da Empresa" />
      <br>
      <select class="form-select" *ngIf="showOrganizationInput" formControlName="typeOrganization">
        <option value="">Selecione o Tipo de Empresa</option>
        <option *ngFor="let type of typeOrganizations" [value]="type.id">{{type.name}}</option>
      </select>
    </div>
  </div>

  <!-- Public Agent City -->
  <div class="form-group row" *ngIf="currentRole === 'PublicAgentCity'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="company">Empresa<span class="required-indicator">*</span></label>
    </div>
    <div  class="col-sm-8" formGroupName="publicAgentDetailsCity">
      <select class="form-select"  id="company" formControlName="company" (change)="handleOrganizationSelection($event)">
        <option value="">Selecione a Empresa</option>
        <option *ngFor="let company of companies" 
            [value]="company.organizationId" 
            [selected]="company.organizationId === personForm.get('publicAgentDetailsCity')?.get('company')?.value">
            {{ company.name }}
        </option>
        <option *ngIf="firstOrganization" [value]="firstOrganization.organizationId" [selected]="firstOrganization.organizationId">{{firstOrganization.name}}</option>
        <option value="none">Não encontrada</option>
      </select>
      <input type="text" class="form-control mt-4" *ngIf="showOrganizationInput" formControlName="newOrganizationName" placeholder="Insira o nome da Empresa" />
      <br>
      <select class="form-select" *ngIf="showOrganizationInput" formControlName="typeOrganization">
        <option value="">Selecione o Tipo de Empresa</option>
        <option *ngFor="let type of typeOrganizations" [value]="type.id">{{type.name}}</option>
      </select>
    </div>
  </div>

  <!-- Public Agent Federal -->
  <div class="form-group row" *ngIf="currentRole === 'PublicAgentFederal'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="company">Empresa<span class="required-indicator">*</span></label>
    </div>
    <div  class="col-sm-8" formGroupName="publicAgentDetailsCountry">
      <select class="form-select"  id="company" formControlName="company" (change)="handleOrganizationSelection($event)">
        <option value="">Selecione a Empresa</option>
        <option *ngIf="firstOrganization" [value]="firstOrganization.organizationId" [selected]="firstOrganization.organizationId">{{firstOrganization.name}}</option>
        <option *ngFor="let item of companiesFromGov" [value]="item.organizationId">{{item.name}}</option>
        <option value="none">Outra</option>
      </select>
      <input type="text" class="form-control mt-4" *ngIf="showOrganizationInput" formControlName="newOrganizationName" placeholder="Insira o nome da Empresa" />
      <br>
      <select class="form-select" *ngIf="showOrganizationInput" formControlName="typeOrganization">
        <option value="">Selecione o Tipo de Empresa</option>
        <option *ngFor="let type of typeOrganizations" [value]="type.id">{{type.name}}</option>
      </select>
    </div>
  </div>

  <!-- Legislative Agent -->
  <div class="form-group row" *ngIf="currentRole === 'LegislativeAgent'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="company">Empresa<span class="required-indicator">*</span></label>
    </div>
    <div  class="col-sm-8" formGroupName="publicAgentDetailsLeg">
      <select class="form-select"  id="company" formControlName="company" (change)="handleOrganizationSelection($event)">
        <option value="">Selecione a Empresa</option>
        <option *ngIf="firstOrganization" [value]="firstOrganization.organizationId" [selected]="firstOrganization.organizationId">{{firstOrganization.name}}</option>
        <option *ngFor="let item of companiesFromGov" [value]="item.organizationId">{{item.name}}</option>
        <option value="none">Não encontrada</option>
      </select>
      <input type="text" class="form-control mt-4" *ngIf="showOrganizationInput" formControlName="newOrganizationName" placeholder="Insira o nome da Empresa" />
      <br>
      <select class="form-select" *ngIf="showOrganizationInput" formControlName="typeOrganization">
        <option value="">Selecione o Tipo de Empresa</option>
        <option *ngFor="let type of typeOrganizations" [value]="type.id">{{type.name}}</option>
      </select>
    </div>
  </div>

  <!-- Judicial Agent -->
  <div class="form-group row" *ngIf="currentRole === 'JudicialAgent'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="company">Empresa<span class="required-indicator">*</span></label>
    </div>
    <div  class="col-sm-8" formGroupName="publicAgentDetailsJud">
      <select class="form-select"  id="company" formControlName="company" (change)="handleOrganizationSelection($event)">
        <option value="">Selecione a Empresa</option>
        <option *ngIf="firstOrganization" [value]="firstOrganization.organizationId" [selected]="firstOrganization.organizationId">{{firstOrganization.name}}</option>
        <option *ngFor="let item of companiesFromGov" [value]="item.organizationId">{{item.name}}</option>
        <option value="none">Outra</option>
      </select>
      <input type="text" class="form-control mt-4" *ngIf="showOrganizationInput" formControlName="newOrganizationName" placeholder="Insira o nome da Empresa" />
      <br>
      <select class="form-select" *ngIf="showOrganizationInput" formControlName="typeOrganization">
        <option value="">Selecione o Tipo de Empresa</option>
        <option *ngFor="let type of typeOrganizations" [value]="type.id">{{type.name}}</option>
      </select>
    </div>
  </div>

  <!-- Executive Agent -->
  <div class="form-group row" *ngIf="currentRole === 'ExecutiveAgent'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="company">Empresa<span class="required-indicator">*</span></label>
    </div>
    <div  class="col-sm-8" formGroupName="publicAgentDetailsExec">
      <select class="form-select"  id="company" formControlName="company" (change)="handleOrganizationSelection($event)">
        <option value="">Selecione a Empresa</option>
        <option *ngIf="firstOrganization" [value]="firstOrganization.organizationId" [selected]="firstOrganization.organizationId">{{firstOrganization.name}}</option>
        <option *ngFor="let item of companiesFromGov" [value]="item.organizationId">{{item.name}}</option>
        <option value="none">Não encontrada</option>
      </select>
      <input type="text" class="form-control mt-4" *ngIf="showOrganizationInput" formControlName="newOrganizationName" placeholder="Insira o nome da Empresa" />
      <br>
      <select class="form-select" *ngIf="showOrganizationInput" formControlName="typeOrganization">
        <option value="">Selecione o Tipo de Empresa</option>
        <option *ngFor="let type of typeOrganizations" [value]="type.id">{{type.name}}</option>
      </select>
    </div>
  </div>

 
  <!-- Entrepreneur Specific Fields -->
  <div class="form-group row" *ngIf="currentRole === 'Entrepreneur'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="company">Empresa<span class="required-indicator">*</span></label>
    </div>
    <div  class="col-sm-8" formGroupName="entrepreneurDetails">
      <select class="form-select"  id="company" formControlName="company" (change)="handleOrganizationSelection($event)">
        <option value="">Selecione a Empresa</option>
        <option *ngIf="firstOrganization" [value]="firstOrganization.organizationId" [selected]="firstOrganization.organizationId">{{firstOrganization.name}}</option>
        <option *ngFor="let item of companies" [value]="item.organizationId">{{item.name}}</option>
        <option value="none">Outra</option>
      </select>
      <input type="text" class="form-control mt-4" *ngIf="showOrganizationInput" formControlName="newOrganizationName" placeholder="Insira o nome da Empresa" />
      <br>
      <select class="form-select" *ngIf="showOrganizationInput" formControlName="typeOrganization">
        <option value="">Selecione o Tipo de Empresa</option>
        <option *ngFor="let type of typeOrganizations" [value]="type.id">{{type.name}}</option>
      </select>
    </div>
  </div>

  <!-- SimpleUser Fields -->
  <div class="form-group row" *ngIf="currentRole === 'CommonUser'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="company">Empresa<span class="required-indicator">*</span></label>
    </div>
    <div  class="col-sm-8" formGroupName="commonUserDetails">
      <select class="form-select"  id="organization" formControlName="organization">
        <option value="">Selecione</option>
        <option *ngFor="let company of companies" 
            [value]="company.organizationId" 
            [selected]="company.organizationId === personForm.get('commonUserDetails')?.get('company')?.value">
            {{ company.name }}
        </option>
        <option *ngIf="firstOrganization" [value]="firstOrganization.organizationId" [selected]="firstOrganization.organizationId">{{firstOrganization.name}}</option>
      </select>
      <input type="text" class="form-control mt-4" *ngIf="showOrganizationInput" formControlName="newOrganizationName" placeholder="Insira o nome da Empresa" />
      <br>
      <select class="form-select" *ngIf="showOrganizationInput" formControlName="typeOrganization">
        <option value="">Selecione o Tipo de Empresa</option>
        <option *ngFor="let type of typeOrganizations" [value]="type.id">{{type.name}}</option>
      </select>
    </div>
  </div>

  <!-- PrivateAgent Specific Fields -->
  <div class="form-group row" *ngIf="currentRole === 'PrivateAgent'">
    <div class="left-column col-sm-4 col-form-label">
      <label for="company">Empresa<span class="required-indicator">*</span></label>
    </div>
    <div class="col-sm-8" formGroupName="privateAgentDetails" *ngIf="currentRole === 'PrivateAgent'">
      <select class="form-select" id="company" formControlName="company" (change)="handleOrganizationSelection($event)">
        <option value="">Selecione a Empresa</option>
        <option *ngIf="firstOrganization" [value]="firstOrganization.organizationId" [selected]="firstOrganization.organizationId">{{firstOrganization.name}}</option>
        <option *ngFor="let item of companies" [value]="item.organizationId">{{item.name}}</option>
        <option value="none">Outra</option>
      </select>
      <!-- New Input Field for Adding a New Organization -->
      <input type="text" class="form-control mt-4" *ngIf="showOrganizationInput" formControlName="newOrganizationName" placeholder="Insira o nome da Empresa" />
      <br>
      <select class="form-select" *ngIf="showOrganizationInput" formControlName="typeOrganization">
        <option value="">Selecione o Tipo de Empresa</option>
        <option *ngFor="let type of typeOrganizations" [value]="type.id">{{type.name}}</option>
      </select>
    </div>
    
  </div>
    
    <!-- Upload Profile Picture -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="profilePicture">Foto Perfil </label>
      </div>
      <div class="col-sm-8">
        <app-file-upload (fileNameChange)="handleFileNameChange($event)" formControlName="urlFoto" [allowedTypes]="['image/*']"></app-file-upload>
      </div>
    </div>

    <!-- Upload Curriculo -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="curriculum">Currículo </label>
      </div>
      <div class="col-sm-8">
        <app-file-upload formControlName="urlCurriculum" [allowedTypes]="['application/pdf']"></app-file-upload>
      </div>
    </div>

    <!-- <span>{{ personForm.value | json }}</span> -->
   
  <!-- Submit Button -->
  <div class="form-group row">
    <div class="col-sm-12 d-flex justify-content-end">
      <button type="submit" mat-raised-button color="warn">Salvar</button>
    </div>
    <div class="spinner">
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>
  </div>
  
</form>

</div>