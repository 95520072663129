<mat-dialog-content class="mat-typography">
  <!-- Seção de Detalhes do Problema -->
  <div class="container">
    <div class="row">

      <!-- Proposta de Soluções para Desafios -->
      <div class="col-md-6 ChallengeProposal" *ngIf="problemDetails">
        <div class="col challengeDetails">
          <h2 class="dialog-section-title mb-4">Detalhes do Desafio</h2>
          <mat-list>
            <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong>Data limite: </strong> {{problemDetails?.limitDate | date:'dd/MM/yyyy'}}</mat-list-item>
              </div>
            </div>
            <hr>
          <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong>Empresa:</strong> {{ problemDetails.ownerName }}</mat-list-item>
              </div>
            </div>
            <div class="row mb-4 line">
              <div class="col  line">
                
                <mat-list-item><strong>Área:</strong> {{ problemDetails.challengeField }}</mat-list-item>
              </div>
            </div>
            <div class="row mb-4 line">
              
              <div class="col">
                
                <mat-list-item><strong>Tema:</strong> {{ problemDetails.theme }}</mat-list-item>
              </div>
            </div>
    
            <div class="row mb-4 line">
              <div class="col" style="line-height: 1.0rem;">
                
                <mat-list-item><strong>Descrição:</strong> {{ problemDetails.description }}</mat-list-item>
              </div>
            </div>
    
            <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong >Maturidade Esperada (TRL):</strong> {{ problemDetails.trl }}</mat-list-item>
              </div>
            </div>
            
            <div class="row mb-4 line">
              
              <!-- <div class="col">
                <mat-list-item><strong>Objetivos: </strong> {{problemDetails.objectives}}</mat-list-item>
              </div> -->
            </div>
            
    
            <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong>Ganhos operacionais:</strong> {{ problemDetails.operationalGain }}</mat-list-item>
              </div>
            </div>
            <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong>Ganhos estratégicos:</strong> {{ problemDetails.economicGain }}</mat-list-item>
              </div>
            </div>
    
            <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong matTooltip="(Faixa de valor do investimento para 1ª versão do produto)">$ Verba Disponível:</strong> {{ problemDetails.availableFees }}</mat-list-item>
              </div>
            </div>
            
          </mat-list>
    
        </div>
      </div>
      
      <div class="col-md-6 solutionDetails" *ngIf="problemDetails">
        <h2 class="dialog-section-title mb-4">Detalhes da Solução</h2>
        <mat-form-field appearance="fill">
          <mat-label>Selecione sua solução</mat-label>
          <mat-select [(ngModel)]="selectedSolutionId" (ngModelChange)="onSolutionSelected($event)">
            <mat-option *ngFor="let solution of userSolutions" [value]="solution.solutionId">
              {{ solution.objectives }}
            </mat-option>
          </mat-select> 
        </mat-form-field>
  
  
        <div *ngIf="selectedSolutionId">
          <!-- <h3>Detalhes da Solução Escolhida</h3> -->
          <!-- Example: Additional details about the solution -->
          <!-- Expose Here a Table with the details of the solution -->
          <div *ngIf="selectedSolutionId">
            <mat-list>
              <div class="row mb-4 line">
                <div class="col">
                  <mat-list-item><strong>Empresa:</strong> {{ selectedSolutionDetails.organizationResponsibleName }}</mat-list-item>
                </div>
              </div>
              <div class="row mb-4 line">
                <div class="col">
                  
                  <mat-list-item><strong>Área:</strong> {{ selectedSolutionDetails.typeOfSolution }}</mat-list-item>
                </div>
      
              </div>
              <div class="row mb-4 line">
                <div class="col">
                  
                  <mat-list-item><strong>Tema:</strong> {{ selectedSolutionDetails.typeOfSolution }}</mat-list-item>
                </div>
              </div>
              <div class="row mb-4 line">
                <div class="col"> 
                  <mat-list-item><strong>Descrição Solução:</strong> {{ selectedSolutionDetails.description }}</mat-list-item>
                </div>
              </div>
      
              <div class="row mb-4 line">
                <div class="col">
                  <mat-list-item><strong>Maturidade Esperada (TRL):</strong> {{ selectedSolutionDetails.trl }}</mat-list-item>
                </div>
              
              </div>
              <!-- <div class="row mb-4 line"> -->
                <!-- <div class="col">
                  
                  <mat-list-item><strong>Objetivos:</strong> {{ selectedSolutionDetails.objectives }}</mat-list-item>
                </div> -->
              <!-- </div> -->
      
              <div class="row mb-4 line">
                <div class="col">
                  <mat-list-item><strong>Ganhos operacionais:</strong> {{ selectedSolutionDetails.operationalGain }}</mat-list-item>
                </div>
              </div>
              <div class="row mb-4 line">
                <div class="col">
                  <mat-list-item><strong>Ganhos estratégicos:</strong> {{ selectedSolutionDetails.economicGain }}</mat-list-item>
                </div>
              </div>
      
              <div class="row mb-4 line">
                <div class="col">
                  <mat-list-item><strong matTooltip="(Faixa de valor do investimento para 1ª versão do produto)">$ Verba Disponível:</strong> {{ selectedSolutionDetails.availableFees }}</mat-list-item>
                </div>
              </div>
              
            </mat-list>
            </div>
  
        </div>
      </div>
    </div>

    <!-- Proposta de Desafios para Soluções -->
    <div class="row">
      <div class="col-md-6 SolutionProposal" *ngIf="solutionDetails">
        <div class="col solutionDetails">
          <h2 class="dialog-section-title mb-">Detalhes da Solução</h2>
          <mat-list>
            <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong>Tipo de Solução: </strong> {{solutionDetails?.typeOfSolution}}</mat-list-item>
              </div>
            </div>
            <hr>
            <div class="row mb-4 line">
              
              <div class="col">
                
                <mat-list-item><strong>TRL:</strong> {{ solutionDetails.trl }}</mat-list-item>
              </div>
            </div>
    
            <div class="row mb-4 line">
              <div class="col" style="line-height: 1.0rem;">
                
                <mat-list-item><strong>Descrição:</strong> {{ solutionDetails.description }}</mat-list-item>
              </div>
            </div>
    
            <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong>Maturidade Esperada (TRL):</strong> {{ solutionDetails.trl }}</mat-list-item>
              </div>
            </div>
            
            <div class="row mb-4 line">
              
              <div class="col">
                <mat-list-item><strong>Objetivos: </strong> {{solutionDetails.objectives}}</mat-list-item>
              </div>
            </div>
            
    
            <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong>Ganhos operacionais:</strong> {{ solutionDetails.operationalGain }}</mat-list-item>
              </div>
            </div>
            <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong>Ganhos estratégicos:</strong> {{ solutionDetails.economicGain }}</mat-list-item>
              </div>
            </div>
    
            <div class="row mb-4 line">
              <div class="col">
                <mat-list-item><strong matTooltip="(Faixa de valor do investimento para 1ª versão do produto)">$ Verba Disponível:</strong> {{ solutionDetails.availableFees }}</mat-list-item>
              </div>
            </div>
            
          </mat-list>
    
        </div>
        
      </div>
      
      <div class="col-md-6 solutionDetails" *ngIf="solutionDetails">
        <h2 class="dialog-section-title mb-4">Detalhes do Desafio</h2>
        <mat-form-field appearance="fill">
          <mat-label>Selecione seu desafio</mat-label>
          <mat-select [(ngModel)]="selectedProblemId" (ngModelChange)="onProblemSelected($event)">
            <mat-option *ngFor="let problem of userProblems" [value]="problem.problemId">
              {{ problem.theme }}
            </mat-option>
          </mat-select> 
        </mat-form-field>
  
  
        <div *ngIf="selectedProblemId">
          <!-- <h3>Detalhes da Solução Escolhida</h3> -->
          <!-- Example: Additional details about the solution -->
          <!-- Expose Here a Table with the details of the solution -->
          <div *ngIf="selectedProblemId">
            <mat-list>
              <div class="row mb-4 line">
                <div class="col">
                  
                  <mat-list-item><strong>Área:</strong> {{ selectedProblemDetails.challengeField }}</mat-list-item>
                </div>
      
              </div>
              <div class="row mb-4 line">
                <div class="col">
                  
                  <mat-list-item><strong>Tema:</strong> {{ selectedProblemDetails.theme }}</mat-list-item>
                </div>
              </div>
              <div class="row mb-4 line">
                <div class="col"> 
                  <mat-list-item><strong>Descrição Desafio:</strong> {{ selectedProblemDetails.description }}</mat-list-item>
                </div>
              </div>
      
              <div class="row mb-4 line">
                <div class="col">
                  <mat-list-item><strong>Maturidade Esperada (TRL):</strong> {{ selectedProblemDetails.trl }}</mat-list-item>
                </div>
              
              </div>
      
              <div class="row mb-4 line">
                <div class="col">
                  <mat-list-item><strong>Ganhos operacionais:</strong> {{ selectedProblemDetails.operationalGain }}</mat-list-item>
                </div>
              </div>
              <div class="row mb-4 line">
                <div class="col">
                  <mat-list-item><strong>Ganhos estratégicos:</strong> {{ selectedProblemDetails.economicGain }}</mat-list-item>
                </div>
              </div>
      
              <div class="row mb-4 line">
                <div class="col">
                  <mat-list-item><strong matTooltip="(Faixa de valor do investimento para 1ª versão do produto)">$ Verba Disponível:</strong> {{ selectedProblemDetails.availableFees }}</mat-list-item>
                </div>
              </div>
              
            </mat-list>
            </div>
  
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="submitProposal()">Enviar Proposta</button>
</mat-dialog-actions>
