
<h1 mat-dialog-title>Assign Role</h1>
<mat-dialog-content class="mat-typography">
  <div>
    <p>Selecione a role para o usuário {{ data.email }}:</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="setUserAsOwner()">Owner</button>
  <button mat-raised-button color="accent" (click)="setUserAsAdmin()">Admin</button>
  <button mat-stroked-button (click)="dialogRef.close()">Cancel</button>
</mat-dialog-actions>