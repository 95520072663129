<app-PersonalNav [title]="'Empresa'"></app-PersonalNav>
<div class="container">
  <hr>

  <!-- List of employees of the same company -->
  <div class="row">
    <!-- create a table with the content -->
    <table class="table table-striped text-center">
      <thead>
        <tr>
          <th scope="col">Nome</th>
          <th scope="col">Email</th>
          <th scope="col">Hierarquia no Sistema</th>
          <th scope="col" >Ações</th>
        </tr>
      </thead>
      <tbody>
        <!-- loop through the employees -->
        <tr *ngFor="let employee of employeesFromOrganization">
          <td>{{employee.name}}</td>
          <td>{{employee.email}}</td>
          <td>{{employee.role}}</td>
          <!-- <td>{{employee.cargo}}</td> -->
          <td>
            <!-- button to remove the employee -->
            <div *ngIf="(employee.role === 'Owner')">Responsável</div>
            <div *ngIf="!(employee.role === 'Owner')">

              <button mat-raised-button color="primary"  (click)="editEmployee(employee)" >Atribuir</button>
              - 
              <button mat-raised-button color="warn" (click)="removeEmployee(employee)" >Remover</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
