import { PersonService } from './../../../services/person.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/api/base/user/user.service';
import { PersonOrganizationService } from 'src/services/personOrganization.service';
import { UserRoleService } from 'src/services/userRole.service';
import { UserRoleAssignmentModel } from 'src/Models/UserRoleAssignmentModel';
import { DialogService } from 'src/services/dialog.service';

@Component({
  selector: 'app-OwnerAdmin',
  templateUrl: './OwnerAdmin.component.html',
  styleUrls: ['./OwnerAdmin.component.css']
})
export class OwnerAdminComponent implements OnInit {

  employees: any[] = [];  
  employeesFromOrganization: any[] = [];
  myOrganization: any;
  user: any;
  constructor(private _person: PersonService,
    private _user: UserService,
    private _userRole: UserRoleService,
    private _dialog: DialogService,
    private _personOrganization: PersonOrganizationService) { }

  ngOnInit() {
    this.user = this._user.obterUsuario();

    this.myOrganization = this._user.obterOrganizacaoUsuario();

    this.getEmployeesFromOrganization();
    this.getPersonOrganizationUser();
  }
  getPersonOrganizationUser() {
    this._personOrganization.getPersonByOrganization(this.myOrganization.organizationId).subscribe(data => {
      this.employeesFromOrganization = data;
    }
    );
  }

  getData() {
    this._person.getAll().subscribe(data => {
      this.employees = data;
    });
  }

  getEmployeesFromOrganization() {
    this._personOrganization.getPersonByOrganization(this.myOrganization.organizationId).subscribe(data => {
      this.employeesFromOrganization = data;
    });
  }

  removeEmployee(email: any) {
    alert('User is now removed');
  }

  editEmployee(personSent: any) {
    //make the employee an admin and change his role in the PersonOrganization table

    this._dialog.editEmployee(personSent, this.user);

  }





}